export const animateSplide = () => {
    window.addEventListener('load', () => {
        document.querySelectorAll('.splide').forEach(node => {
            new Splide(node, {
                rewind: true,
                drag: false,
                autoplay: false,
                perPage: 1,
                pagination: true,
                gap: '24px'
            }).mount()
        })
    })
}
// import { gsap } from "gsap"
// import { ScrollTrigger } from "gsap/ScrollTrigger"

// gsap.registerPlugin(ScrollTrigger) //ScrollSmoother

// const checkIframeLoaded = new Promise((resolve) => {
//     setTimeout(() => {
//         resolve(true) 
//     }, 600)
// })

export const animateComponents = () => {
    // window.addEventListener('load', () => {
    //     checkIframeLoaded.then(() => {
    //         //stepper
    //         document.querySelectorAll('.animate-stepper').forEach(el => {
    //             const container = el.querySelector('.animate-stepper-container')
    //             const cards = el.querySelectorAll('.animate-stepper-card')
    //             const photos = gsap.utils.toArray(container?.querySelectorAll('.animate-stepper-image'))
    //             gsap.set(photos, {yPercent: 101})

    //             cards.forEach((card, i) => {
    //                 const headline = card.querySelector('h2')
    //                 ScrollTrigger.create({
    //                     trigger: headline,
    //                     start: "top 80%",
    //                     end: "top 50%",
    //                     animation: gsap.to(photos[i], {yPercent: 0}),
    //                     scrub: true,
    //                 })
    //             })
    //         })

    //         // sticky
    //         document.querySelectorAll('.animate-sticky').forEach(el => {
    //             const container = el?.dataset?.container ? el?.dataset?.container : null
    //             const start = el?.dataset?.start ? el?.dataset?.start : '0'
    //             const end = el?.dataset?.end ? el?.dataset?.end : `100%`

    //             if (container) {
    //                 ScrollTrigger.create({
    //                     trigger: document.getElementById(`#${container}`),
    //                     pin: el,
    //                     start: `-=${start} top`,
    //                     end: `bottom ${end}`,
    //                     scrub: false,
    //                 })
    //             }
    //         })
    //     })
    // })
}